import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useGetAmenitiesImagesQuery, useGetAmenityTypesQuery } from '../services/amenities'
import { useGetPropertiesQuery, useGetPropertyTypesQuery, useLazyGetPropertyQuery } from '../services/properties'
import { Property } from '../types/property'
import { PropertyType } from '../types/propertyType'
import { MapFiltersType, PageContext } from './PageContext'

interface PageProviderProps {
    children: React.ReactNode
}

export type CurrencyData = {
    [key: string]: number
}

export type FiltersButton = {
    id: number
    slug: string
    name: string
    image: string | undefined
    active: boolean
    pages: string[]
}

const url = 'https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/sar.json'
const fallbackUrl = 'https://currency-api.pages.dev/v1/currencies/sar.json'

export const PageProvider: React.FC<PageProviderProps> = ({ children }) => {
    const { t, i18n } = useTranslation()
    const location = useLocation()
    const [getProperty] = useLazyGetPropertyQuery()
    const { data: fetchedProperties } = useGetPropertiesQuery()
    const { data: amenityTypes } = useGetAmenityTypesQuery()
    const { data: amenityImages } = useGetAmenitiesImagesQuery()
    const { data: propertyTypes } = useGetPropertyTypesQuery()

    const [activeButton, setActiveButton] = useState<string | null>(null)
    const [subActiveButton, setSubActiveButton] = useState<string | null>(null)
    const [displayedProperty, setDisplayedProperty] = useState<Property | null>(null)
    const [selectedRoom, setSelectedRoom] = useState('TV Area')
    const [selectedFilter, setSelectedFilter] = useState<string | null>(null)
    const [mapZoom, setMapZoom] = useState(0.3)
    const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({ lat: 0, lng: 0 })
    const [mapFilters, setMapFilters] = useState<{ key: MapFiltersType; value: string | number | string[] | number[] }[]>([
        { key: 'status', value: ['available', 'booked', 'sold'] },
        { key: 'unitType', value: [] },
        { key: 'plotArea', value: [0, 100] },
    ])
    const [isUnitInformationsOpen, setIsUnitInformationsOpen] = useState(false)
    const [isVRMode, setIsVRMode] = useState(false)
    const [areCaptionsOn, setAreCaptionsOn] = useState(true)
    const [activeAmenitiesButtons, setActiveAmenitiesButtons] = useState<{
        [key: string]: boolean
    }>({
        hospital: false,
        park: false,
        retail: false,
        pocketPark: false,
        mosque: false,
        school: false,
    })
    const [activeFilterButtons, setActiveFilterButtons] = useState<FiltersButton[] | []>([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [properties, setProperties] = useState<Property[]>([])
    const [displayedProperties, setDisplayedProperties] = useState<Property[]>([])
    const [currencies, setCurrencies] = useState<CurrencyData>({})
    const [currentFloor, setCurrentFloor] = useState<number>(32)
    const [isFirstLoad, setIsFirstLoad] = useState(true)
    const [cameraUrl, setCameraUrl] = useState('')
    const iframeVR = useRef<HTMLIFrameElement | null>(null)
    const [viewSide, setViewSide] = useState<number>(1)
    const [displayedLocation, setDisplayedLocation] = useState<number | null>(null)
    const [clickedUnit, setClickedUnit] = useState<Property | null>(null)
    const [disclaimerIsOpen, setDisclaimerIsOpen] = useState(false)
    const [clickedGallery, setClickedGallery] = useState<string | null>(null)
    const [currentPropertyType, setCurrentPropertyType] = useState<PropertyType | null>(null)

    const fetchProperty = async (id: string) => {
        const property = getProperty(Number(id))

        return property
    }

    useEffect(() => {
        if (fetchedProperties && fetchedProperties.length > 0) {
            setProperties(fetchedProperties)
        }
    }, [fetchedProperties])

    useEffect(() => {
        if (properties) {
            const filteredProperties = properties.filter(property => {
                const status = (mapFilters.find(filter => filter.key === 'status')?.value as string[]) || []
                const unitType = (mapFilters.find(filter => filter.key === 'unitType')?.value as number[]) || []
                const plotArea = (mapFilters.find(filter => filter.key === 'plotArea')?.value as number[]) || [0, 1000]

                const unitTypesMatch = unitType.length > 0 && property['type-properties'].some(id => unitType.includes(id))
                const plotAreaMatch = property.unit_area === '' || (Number(property.unit_area) >= plotArea[0] && Number(property.unit_area) <= plotArea[1])
                const statusMatch = status.length === 0 || status.includes(property.unit_status.toLowerCase())

                return unitTypesMatch && plotAreaMatch && statusMatch
            })

            setDisplayedProperties(filteredProperties)
        }
    }, [mapFilters, properties])

    useEffect(() => {
        if (amenityTypes && amenityImages) {
            const buttons: FiltersButton[] = amenityTypes.map(type => {
                return {
                    id: type.id,
                    slug: type.slug,
                    name: type.name,
                    image: amenityImages.find(image => image.id === type.id)?.media_details.sizes.medium.source_url,
                    active: true,
                    pages: type.pages,
                }
            })

            setActiveFilterButtons(buttons)
        }
    }, [amenityTypes, amenityImages])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const propertyId = urlParams.get('property')
        const path = window.location.pathname

        if (propertyId && isFirstLoad) {
            ;(async () => {
                const matchedProperty = await fetchProperty(propertyId)

                // if (matchedProperty && path === '/masterplan') {
                //   setDisplayedProperty(matchedProperty)
                //   setActiveButton('unitInformation')
                // } else if (matchedProperty && path === '/house-plan') {
                //   setDisplayedProperty(matchedProperty)
                // }
                setIsFirstLoad(false)
            })()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setIsFirstLoad, isFirstLoad, setDisplayedProperty, setActiveButton])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const path = window.location.pathname

        if (displayedProperty && (path === '/masterplan' || path === '/house-plan')) {
            urlParams.set('property', displayedProperty.id.toString())
            window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`)
        } else if (!isFirstLoad && !displayedProperty) {
            urlParams.delete('property')
            window.history.pushState({}, '', `${window.location.pathname}`)
        }
    }, [displayedProperty, isFirstLoad, setDisplayedProperty])

    useEffect(() => {
        if (!isFirstLoad) {
            const path = window.location.pathname
            const urlParams = new URLSearchParams(window.location.search)

            if (path === '/masterplan' && activeButton !== 'unitInformation') {
                urlParams.delete('property')
                window.history.pushState({}, '', `${window.location.pathname}`)
                setDisplayedProperty(null)
            }
        }
    }, [activeButton, isFirstLoad, setDisplayedProperty])

    useEffect(() => {
        const currentPath = location.pathname

        if (currentPath !== '/masterplan') {
            setActiveButton(null)
            setSubActiveButton(null)
        }

        if (currentPath !== '/house-plan' && isVRMode) {
            setIsVRMode(false)
        }
    }, [location, isVRMode])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const floorId = urlParams.get('floor')

        if (floorId !== null) {
            setCurrentFloor(Number(floorId))
        }
    }, [setCurrentFloor])

    useEffect(() => {
        const path = window.location.pathname

        if (displayedProperty && currentFloor !== null && path === '/house-plan') {
            const urlParams = new URLSearchParams(window.location.search)
            urlParams.set('floor', currentFloor.toString())
            window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`)
        }
    }, [currentFloor, displayedProperty])

    useEffect(() => {
        ;(async () => {
            try {
                await fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        setCurrencies(data.sar)
                    })
            } catch (err) {
                await fetch(fallbackUrl)
                    .then(response => response.json())
                    .then(data => {
                        setCurrencies(data.sar)
                    })
            }
        })()
    }, [])

    useEffect(() => {
        if (properties && properties.length > 0) {
            const minPlotArea = Math.min(...properties.map(property => Number(property.unit_area))) ?? 0
            const maxPlotArea = Math.max(...properties.map(property => Number(property.unit_area))) ?? 100

            setMapFilters(prevFilters => [
                { key: 'status', value: ['available', 'booked', 'sold'] },
                { key: 'unitType', value: [] },
                { key: 'plotArea', value: [minPlotArea, maxPlotArea] },
            ])
        }
    }, [properties])

    useEffect(() => {
        // Update the selectedRoom when the language changes
        if (i18n.language === 'ar') {
            setSelectedRoom(t('rooms.tvArea'))
        } else {
            setSelectedRoom('TV Area')
        }
    }, [i18n.language, t])

    const toggleVRMode = () => {
        // if (displayedProperty?.property_type?.floors[0].points[0].url) {
        //   setCameraUrl(displayedProperty?.property_type?.floors[0].points[0].url)
        // }
        setIsVRMode(!isVRMode)
    }

    const resetButtons = () => {
        setActiveAmenitiesButtons({
            hospital: false,
            park: false,
            retail: false,
            pocketPark: false,
            mosque: false,
            school: false,
        })
        setIsVRMode(false)
    }

    const toggleUnitInformations = () => {
        setIsUnitInformationsOpen(!isUnitInformationsOpen)
    }

    const toggleCaptions = () => {
        setAreCaptionsOn(!areCaptionsOn)
    }

    const setMapFiltersWrapper = ({ key, value }: { key: string; value: string | number | string[] | number[] }) => {
        const newMapFilters = mapFilters.map(filter => {
            if (filter.key === key) {
                return { key, value }
            } else {
                return filter
            }
        })

        setMapFilters(newMapFilters)
    }

    const getMapFilter = (key: MapFiltersType) => {
        return mapFilters.find(filter => filter.key === key)?.value
    }

    const resetMapFilters = () => {
        if (properties && properties.length > 0) {
            const minPlotArea = Math.min(...properties.map(property => Number(property.unit_area))) ?? 0
            const maxPlotArea = Math.max(...properties.map(property => Number(property.unit_area))) ?? 100

            setMapFilters([
                { key: 'status', value: ['available', 'booked', 'sold'] },
                { key: 'unitType', value: [] },
                { key: 'plotArea', value: [minPlotArea, maxPlotArea] },
            ])
        }
    }

    useEffect(() => {
        if (isFirstLoad && location.pathname === '/house-plan') {
            const urlParams = new URLSearchParams(window.location.search)

            if (urlParams.get('side') === 'back') {
                setViewSide(2)
                setActiveButton(t('nav.view'))
            } else {
                setViewSide(1)
            }

            setIsFirstLoad(false)
        }
    }, [isFirstLoad, t, location])

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search)
        const locationId = urlParams.get('location')

        if (locationId && location.pathname === '/page-360') {
            setDisplayedLocation(Number(locationId))
        }
    }, [location])

    useEffect(() => {
        if (!isFirstLoad && location.pathname === '/house-plan') {
            const urlParams = new URLSearchParams(window.location.search)

            if (activeButton === t('nav.view')) {
                setViewSide(2)
                urlParams.set('side', 'back')
            } else {
                setViewSide(1)
                urlParams.set('side', 'front')
            }

            window.history.pushState({}, '', `${window.location.pathname}?${urlParams}`)
        }
    }, [activeButton, isFirstLoad, t, location])

    useEffect(() => {
        if (propertyTypes && clickedUnit) {
            const type = propertyTypes.find(type => clickedUnit.unit_type.includes(type.id) && !type.parent)
            setCurrentPropertyType(type ?? null)
        }
    }, [propertyTypes, clickedUnit])

    return (
        <PageContext.Provider
            value={{
                pageTransitionTiming: 1000,
                resetButtons,
                activeButton,
                setActiveButton,
                setSubActiveButton,
                subActiveButton,
                displayedProperty,
                setDisplayedProperty,
                isVRMode,
                toggleVRMode,
                setIsVRMode,
                selectedRoom,
                setSelectedRoom,
                selectedFilter,
                setSelectedFilter,
                mapFilters,
                setMapFilters: setMapFiltersWrapper,
                getMapFilter,
                resetMapFilters,
                areCaptionsOn,
                toggleCaptions,
                isUnitInformationsOpen,
                toggleUnitInformations,
                activeAmenitiesButtons,
                setActiveAmenitiesButtons,
                activeFilterButtons,
                setActiveFilterButtons,
                isModalOpen,
                setIsModalOpen,
                properties,
                setProperties,
                displayedProperties,
                setDisplayedProperties,
                mapZoom,
                setMapZoom,
                mapCenter,
                setMapCenter,
                currencies,
                currentFloor,
                setCurrentFloor,
                cameraUrl,
                setCameraUrl,
                iframeVR,
                viewSide,
                setViewSide,
                displayedLocation,
                setDisplayedLocation,
                clickedUnit,
                setClickedUnit,
                disclaimerIsOpen,
                setDisclaimerIsOpen,
                clickedGallery,
                setClickedGallery,
                currentPropertyType,
            }}>
            {children}
        </PageContext.Provider>
    )
}
